<template>
    <div>
        <v-container>
            <notification-popup
                :dialog="notifQRFailed"
                :contentMsg="contentMsg"
                :headerMsg="$t('FailedQrGenerateHeader')"
                :nameBtnNo="$t('labelNo')"
                :nameBtnYes="$t('Back')"
                colorIcon="danger"
                typeModal="transaction"
                Icon="mdi-close-circle"
                disableBtnNo="true"
                imgSource="notification/failed.svg"
                :onHandlerYes="buttonBack"
            />

            <notification-popup  
                :dialog="modalUsageCPM"
                :contentMsg="$t('Enjoyyourtrip')"
                :headerMsg="$t('ValidationSuccess')"
                :nameBtnNo="$t('labelNo')"
                :nameBtnYes="$t('BacktoRecentTrip')"
                disableBtnNo="true"
                colorIcon = "success"
                typeModal="transaction"
                Icon="mdi-check-circle"
                imgSource="notification/success2.svg"
                :onHandlerNo="cancelSuccess"
                :onHandlerYes="buttonOke"
            />
            
            <!-- <v-row class="mx-0 box">
                <v-col cols="12">
                    <h3 class="grey--text font-weight-regular">{{$t("User")}}</h3>
                    <h2>{{name}}</h2>
                </v-col>
            </v-row> -->
            <v-row class="mx-0 box mt-4" justify="center">
                <v-col cols="12">
                    <qrcode-vue :value="qrCode" :size="300" class="mt-10 mb-0 text-center"/>
                </v-col>
                <v-col class="text-center">
                    <span class="link" @click="loadInitial">{{$t("Refresh")}}</span>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import NotificationPopup from "@/components/NotificationPopup.vue";
export default {
    name: "ShowQRCode",
    components: {
        QrcodeVue,
        NotificationPopup
    },
    data() {
        return {
            ticketId: this.$route.params.data,
            qrCode: '',
            notifQRFailed: false,
            contentMsg: ''
        }
    },
    computed: {
        modalUsageCPM() {
            return this.$store.state.notification.modalUsage
        }
    },
    watch: {
        modalUsageCPM: function () {
            
        }
    },
    methods: {
        async generateQrCode() {
            const payload = {
                ticket_id: this.ticketId
            }
            await this.$store
                .dispatch("cpm/GenerateQR", payload)
                .then((response) => {
                    if (response.status) {
                        this.qrCode = response.data.data_qr
                    } else {
                        let message = response.message
                        if (message === '') {
                            message = 'PleaseTryAgain'
                        }
                        const splitmsg = message.split('-')
                        if (splitmsg.length > 1) {
                            this.contentMsg = `${this.$t(splitmsg[0])} ${this.$t(splitmsg[1])} ${this.$t(splitmsg[2])}`
                        } else {
                            this.contentMsg = this.$t(message)
                        }
                        this.notifQRFailed = true
                    }
                })
                .catch((err) => {
                    console.log(err);
                    // this.contentMsg = this.$t('PleaseTryAgain')
                    // this.notifQRFailed = true
                });
        },
        buttonBack() {
            this.$router.go(-1)
        },
        loadInitial() {
            this.generateQrCode()
        },
        cancelSuccess() {
            this.$store.commit("notification/SET_MODAL_USAGE", false);
        },
        buttonOke() {
            this.$store.commit("notification/SET_MODAL_USAGE", false);
            this.$router.push({ name: "MyTrip" });
        },
    },
    mounted() {
        this.loadInitial()
    }
}
</script>

<style lang="scss" scoped>
.box {
    background: #FFFFFF;
    border: 1px solid #4BB14E;
    border-radius: 10px;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 10%), 0px 4px 8px rgb(0 0 0 / 5%);
}

.link {
    color: #4BB14E;
    text-decoration: underline;
}
</style>